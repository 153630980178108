import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import AdminHome from './components/admin/AdminHome'
import AdminLogin from './components/admin/AdminLogin'
import SetUpTickets from './components/admin/SetUpTickets';
import SetUpQuestions from './components/admin/SetUpQuestions';
import SetUpGame from './components/admin/SetUpGame';
import SetUpTeamVariables from './components/admin/SetUpTeamVariables';
import SetUpRulesAndRegs from './components/admin/SetUpRulesAndRegs';
import SetUpTicketEmail from './components/admin/SetUpTicketEmail';
import LoginVariables from './components/admin/SetUpLoginVariables';
// import ScoreboardEditor from './components/admin/ScoreboardEditor';
// import ErrorPage from './components/utils/ErrorPage';
// import AuthService from './components/utils/AuthService';
import { app } from './base'

function AuthenticatedAdminRoute({component: Component, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
              if(authenticated === true){
                return (
                  <Component {...props} {...rest} />
                )
              } else {
                return (
                  <Redirect to={{pathname: '/adminLogin', state: {from: props.location}}} />
                )
              }
              }} />
    )
}

// function AuthenticatedRoute({component: Component, authenticated, ...rest}) {
//     return (
//         <Route
//             {...rest}
//             render={(props) => {
//               if(authenticated === true){
//                 return (
//                   <Component {...props} {...rest} />
//                 )
//               } else {
//                 return (
//                   <Redirect to={{pathname: '/login', state: {from: props.location}}} />
//                 )
//               }
//               }} />
//     )
// }

class App extends Component {
  constructor(props) {
        super(props)
        // this.setTheme = this.setTheme.bind(this)
        // this.checkIfAuthenticated = this.checkIfAuthenticated.bind(this);
        // var teamLocation = window.location.pathname.split('/')[1] || ""
        // localStorage.setItem('team'+teamLocation, teamLocation);
        this.setCurrentUser = this.setCurrentUser.bind(this)
        // this.Auth = new AuthService();
        this.state = {
            // admin: false,
            authenticated: false,
            currentUser: null,
            loading: true,
        }
    }

    setTheme(){
      var teamLocation = window.location.pathname.split('/')[1] || ""
      var currentUrl = 'https://scratchers.sqwadapp.co/' + localStorage.getItem('team'+teamLocation)
      var tenantObject = {'tenantUrl': currentUrl}
      //set up theme:
      //primary color, secondary color, Top left image, Front Logo image, background image, Scratcher Image, buy tickets link
      this.Auth.fetch(`${this.Auth.domain}services/app/game/GetTenantInfoFromTenantUrl`, {
        method: 'POST',
        headers: {
	         'Content-Type': 'application/json',
         },
        body: JSON.stringify(tenantObject),
      }).then(res => {
        console.log("Successfully found team!")
        var tenantVariables = res.entity;
        if(tenantVariables){
          localStorage.setItem('rules-text'+teamLocation, tenantVariables.rulesAndRegsText)
          localStorage.setItem('rules-link'+teamLocation, tenantVariables.rulesAndRegsLink)
          localStorage.setItem('scratcher-top-image'+teamLocation, tenantVariables.scratcherImage)
          localStorage.setItem('frontLogo'+teamLocation, tenantVariables.frontLogoImage)
          localStorage.setItem('primaryColor'+teamLocation, tenantVariables.primaryColor)
          localStorage.setItem('secondaryColor'+teamLocation, tenantVariables.secondaryColor)
          localStorage.setItem('ticketsLink'+teamLocation, tenantVariables.buyTicketURL)
          localStorage.setItem('topLeftLogo'+teamLocation, tenantVariables.topLeftImage)
          localStorage.setItem('TenantId'+teamLocation, tenantVariables.id)
          localStorage.setItem('backgroundImage'+teamLocation, tenantVariables.backgroundImage)
        }
      }).catch(err =>{
        console.log(err)
      })
    }

    // checkIfAuthenticated() {
    //   if(this.Auth.loggedIn() && this.Auth.isAdmin()){
    //     var user = this.Auth.getProfile();
    //     var isAdmin = this.Auth.isAdmin();
    //     this.setState({
    //         authenticated: true,
    //         admin: isAdmin,
    //         currentUser: user,
    //     })
    //   } else {
    //     this.setState({
    //         authenticated: false,
    //         admin: false,
    //         currentUser: null,
    //     })
    //   }
    // }

  setCurrentUser(user) {
    if (user) {
        this.setState({
            currentUser: user,
            authenticated: true
        })
    } else {
        this.setState({
            currentUser: null,
            authenticated: false
        })
    }
  }

    componentDidMount() {
        this.removeAuthListener = app.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    authenticated: true,
                    currentUser: user,
                    loading: false,
                })
            } else {
                this.setState({
                    authenticated: false,
                    currentUser: null,
                    loading: false,
                })
            }
        })
    }

    componentWillUnmount() {
        this.removeAuthListener()
    }

  render() {
    if (this.state.loading === true) {
        return (
            <Loading loading={this.state.loading}/>
        )
    }
    return (
      <div style={{ margin: "0 auto" }}>
                <BrowserRouter>
                    <div>
                        <div className="main-content">
                            <div className="workspace">
                              <Switch>

                                <Route
                                  exact
                                  path="/adminlogin"
                                  user={this.state.currentUser}
                                  render={(props) => {
                                    return <AdminLogin setCurrentUser={this.setCurrentUser} {...props} />
                                  }} />

                                <AuthenticatedAdminRoute
                                    exact
                                    path="/admin"
                                    authenticated={this.state.authenticated}
                                    component={AdminHome} />

                                <AuthenticatedAdminRoute
                                    exact
                                    path="/setupgame"
                                    authenticated={this.state.authenticated}
                                    component={SetUpGame} />

                                <AuthenticatedAdminRoute
                                    exact
                                    path="/setupquestions"
                                    authenticated={this.state.authenticated}
                                    component={SetUpQuestions} />

                                <AuthenticatedAdminRoute
                                    exact
                                    path="/setuptickets"
                                    authenticated={this.state.authenticated}
                                    component={SetUpTickets} />

                                <AuthenticatedAdminRoute
                                    exact
                                    path="/setupteamvariables"
                                    authenticated={this.state.authenticated}
                                    component={SetUpTeamVariables} />

                                <AuthenticatedAdminRoute
                                    exact
                                    path="/setuprulesandregs"
                                    authenticated={this.state.authenticated}
                                    component={SetUpRulesAndRegs} />

                                <AuthenticatedAdminRoute
                                    exact
                                    path="/setupticketemail"
                                    authenticated={this.state.authenticated}
                                    component={SetUpTicketEmail} />

                              <AuthenticatedAdminRoute
                                  exact
                                  path="/setuploginvariables"
                                  authenticated={this.state.authenticated}
                                  component={LoginVariables} />

                                {/*<AuthenticatedAdminRoute*/}
                                {/*    exact*/}
                                {/*    path="/scoreboardeditor"*/}
                                {/*    authenticated={this.state.authenticated}*/}
                                {/*    component={ScoreboardEditor} />*/}

                                <Redirect to="/adminlogin"/>
                              </Switch>
                            </div>
                        </div>
                    </div>
                </BrowserRouter>
            </div>
    );
  }
}

export default App;
